/* 
 * @Author: 曹俊杰 
 * @Date: 2022-08-08 10:07:54
 * @Module: 组织机构管理
 */
 <template>
  <div class="Org">
    <!-- <div class="top">
      <searchForm :searchForm.sync="listParams" @submit="getOrgList" />
    </div> -->
    <div class="content">
      <div class="title">
        <span>组织机构</span>
        <div>
          <el-button type="primary" size="small" @click="add">添加组织机构</el-button>
        </div>
      </div>
      <div class="main">
        <el-table size="mini" :data="list" tooltip-effect="dark" style="width: 100%;">

          <el-table-column label="组织机构" show-overflow-tooltip prop="name">
            <template slot-scope="scope">
              <span>{{ scope.row.name||"--" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="创建时间" show-overflow-tooltip prop="createdAt">
            <template slot-scope="scope">
              <span>{{ scope.row.createdAt |timeFormat('yyyy-mm-dd hh:MM:ss')  }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="60" :resizable="false">
            <template slot-scope="scope">
              <el-button type="text" size="mini" @click="()=>revision(scope.row)">编辑</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <c-pagination ref="pagination" layout="prev, pager, next,sizes" @change="getOrgList" />
    </div>

    <OrgForm @success="getOrgList" ref="OrgForm" />

  </div>
</template>
 <script>
import OrgForm from "@/views/Org/components/OrgForm"
// import searchForm from "@/views/Org/components/searchForm"
export default {
  components: {
    OrgForm,
    // searchForm
  },
  data () {

    return {

      list: [],
      listParams: {
        keyword: '',
        role: ''
      }
    };
  },
  mounted () {
    this.getOrgList()
  },
  methods: {
    add () {
      this.$refs.OrgForm.show({ type: 'add' });
    },
    revision (data) {
      this.$refs.OrgForm.show({ type: 'revision', data });
    },
    async getOrgList () {

      const { current, size } = this.$refs.pagination
      const { listParams } = this
      const { status, data } = await this.$api.getOrgList({ current, size, ...listParams })
      if (status == 200) {
        this.list = data;
        this.$refs.pagination.createPagination(data)
      }

    }
  },
};
 </script>
 <style lang='scss' scoped>
.Org {
  .top {
    height: 68px;
    overflow: hidden;
    background: #fff;
    padding: 0 16px;
  }
  .content {
    background: #fff;
    padding: 16px;
    padding-top: 0;
    .title {
      display: flex;
      line-height: 56px;
      justify-content: space-between;
    }
  }
}
</style>