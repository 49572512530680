/* 
 * @Author: 曹俊杰 
 * @Date: 2022-08-11 11:45:50
 * @Module: 组织机构管理表单
 */
 <template>
  <el-dialog
    :title="type == 'add' ? '添加组织机构' : type == 'revision' ? '修改组织机构' : ''"
    :visible.sync="dialogVisible"
    width="500px"
    @closed="closed"
  >
    <div style="padding: 20px">
      <el-form
        size="mini"
        label-position="left"
        :rules="rules"
        label-width="130px"
        :model="form"
        ref="form"
      >
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="组织机构名称" prop="name">
              <el-input
                v-model="form.name"
                placeholder="请输入组织机构名称"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false" size="mini">取 消</el-button>
      <el-button type="primary" @click="submit" size="mini">确 定</el-button>
    </span>
  </el-dialog>
</template>
 <script>
// import team from "@/views/AccountManagement/components/form/team";
export default {
  components: {
    // team,
  },
  data() {
    return {
      type: "add",
      dialogVisible: false,
      form: {
        orgId: null,
        name: "",
      },
      //rules前端验证
      rules: {
        name: [{ required: true, message: "组织机构名不能为空", trigger: "blur" }],
      },
    };
  },
  mounted() {},
  methods: {
    show({ type = "add", data }) {
      if (type == "revision") {
        this.form = this.$utils.deepClone(data);
      }
      this.type = type;
      this.dialogVisible = true;
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.putAccount();
        } else {
          return false;
        }
      });
    },
    closed() {
      this.form = {
        orgId: null,
        name: ""
      };
    },
    async putAccount() {
      const api = {
        revision: this.$api.putOrg,
        add: this.$api.postOrg,
      };
      const { info, status } = await api[this.type](this.form);
      if (status === 200) {
        this.$message({
          type: "success",
          message: info,
        });
        this.$emit("success", this.type);
        this.dialogVisible = false;
      } else {
        this.$message({
          type: "error",
          message: info,
        });
      }
    },
  },
};
</script>
 <style lang='scss' scoped>
</style>